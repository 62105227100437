<script setup lang="ts">
const { t } = useT();
const prepareImgUrl = useImage();

const imageBg = `url(${prepareImgUrl("/nuxt-img/magic-boxes/magic-pumpkins/nameplate-lines.png", {
	format: "webp",
	loading: "lazy",
	width: 594,
	height: 226
})})`;
</script>

<template>
	<div class="nameplate">
		<NuxtImg
			class="plate"
			src="/nuxt-img/magic-boxes/magic-pumpkins/nameplate.png"
			loading="lazy"
			format="webp"
			alt="jungle secrets"
			width="573"
			height="314"
		/>
		<AText
			class="date"
			:size="{ full: 20, md: 10 }"
			as="div"
			gradient="linear-gradient(180deg, #E4CDB9 0%, #A69485 100%)"
		>
			<span>{{ t("magic-boxes.skin.date") }}</span>
		</AText>
		<div class="spider-web" />
		<NuxtImg
			class="spider"
			src="/nuxt-img/magic-boxes/magic-pumpkins/spider.png"
			loading="lazy"
			format="webp"
			alt="spider"
			width="70"
			height="70"
		/>
	</div>
</template>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

.nameplate {
	max-width: 420px;
	width: 100%;
	position: relative;
	z-index: 3;
	text-align: center;

	&::before {
		content: "";
		position: absolute;
		z-index: 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 150%;
		height: 100%;
		background: v-bind(imageBg) no-repeat center center / contain;
	}
	.plate {
		position: relative;
		filter: drop-shadow(0 0 6px #f257ff);
		animation: shad 2500ms ease-in-out infinite;
	}
	img {
		width: 100%;
		height: auto;
	}
	.spider {
		position: absolute;
		width: 15%;
		z-index: 2;
		top: 40%;
		right: 11%;
		animation: spider 5s ease-in-out infinite;
		&-web {
			content: "";
			position: absolute;
			z-index: 0;
			top: 49%;
			right: 18.6%;
			width: 1px;
			height: 0;
			background: rgba(228, 205, 185, 0.2);
			animation: spider-web 5s ease-in-out infinite;
		}
	}
	.date {
		position: absolute;
		z-index: 2;
		top: 65%;
		left: 50%;
		transform: translateX(-50%);
		font-family: "Lilita One", sans-serif;
		filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
	}
}
@keyframes shad {
	50% {
		filter: drop-shadow(0 10px 20px rgba(242, 87, 255, 0.5));
	}
}
@keyframes spider {
	80% {
		top: 76%;
	}
	100% {
		top: 40%;
	}
}
@keyframes spider-web {
	80% {
		height: 35%;
	}
	88% {
		height: 25%;
	}
	100% {
		height: 0;
	}
}
@keyframes rotateStripes {
	100% {
		opacity: 0.3;
	}
}
</style>
